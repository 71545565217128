.lineClamp {display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.ellipsis {overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.banner                 {
    &-item              {display: block;position: relative;padding-bottom: 56%;
        img             {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
    }
}

.hot                    {background: #FFF;position: relative;
    &:before            {content: '';display: block;background: -webkit-gradient(linear,left top,right top,color-stop(0,hsla(0,0%,100%,0)),color-stop(100%,#fff));width: 1.4rem;height: 100%;position: absolute;right: 0;top: 0;z-index: 10;}
    .hd                 {display: flex;align-items: center;padding: .7rem .4rem .4rem;width: 100%;justify-content: space-between;
        span            {.fs(20);font-weight: 600;color: #333;}
    }
    .content            {width: 100%;overflow: auto;position: relative;
        &::-webkit-scrollbar {width: 0;height: 0;}
    }
    .scroll             {display: flex;flex-wrap: wrap;padding: 0 .28rem .8rem;}
    .item               {padding: 0 .12rem .12rem;width: 3.4rem;box-sizing: border-box;box-shadow: .2rem .1rem .4rem #f1f1f1;}
    a                   {display: flex;flex-direction: column;
        .pic            {position: relative;overflow: hidden;border-radius: .14rem .14rem 0 0;z-index: 10;
            &:before    {content: '';display: block;padding-bottom: 100%;}
            img         {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
        }
        .info           {padding: .22rem .1rem .1rem;
            .title      {.fs(15);font-weight: 600;color: #333;.ellipsis;}
            .hits       {.fs(13);color: #a0a4a3;}
        }
    }
}

.loop                   {margin-top: .4rem;background: #FFF;
    .hd                 {display: flex;align-items: center;padding: .7rem .4rem 0;width: 100%;justify-content: space-between;
        span            {.fs(20);font-weight: 600;color: #333;}
    }
    &-item              {padding: .34rem;display: flex;position: relative;
        .pic            {position: relative;width: 2.346667rem;height: 2.346667rem;overflow: hidden;border-radius: .1356rem;z-index: 10;
            &:before    {content: '';display: block;padding-bottom: 100%;}
                img     {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
        }
        .info           {flex: 1;overflow: hidden;margin: 0 0 0 .34rem;position: relative;
            .title      {.fs(15);font-weight: bold;color: #333;.lineClamp;line-height: .6rem;}
            .desc       {.fs(13);color: #999;margin: .1rem 0;.ellipsis;margin-right: 1.6rem;flex: 1;}
            p           {.fs(12);position: absolute;bottom: 0;left: 0;}
        }
        .price          {position: absolute;right: .4rem;bottom: .34rem;text-align: center;
            .yj         {color:#bdbdbd;.fs(12);text-decoration: line-through;margin-bottom: .06rem;}
            .money      {color: #fea130;.fs(16);border: .02rem solid #fea130;border-radius: .4rem;padding: 0 .4rem;
                span    {.fs(16);}
            }
        }
        .free           {position: absolute;right: .4rem;bottom: .34rem;text-align: center;color: #5ec2b5;}
    }
}