.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.banner-item {
  display: block;
  position: relative;
  padding-bottom: 56%;
}
.banner-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hot {
  background: #FFF;
  position: relative;
}
.hot:before {
  content: '';
  display: block;
  background: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), color-stop(100%, #fff));
  width: 1.4rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.hot .hd {
  display: flex;
  align-items: center;
  padding: .7rem .4rem .4rem;
  width: 100%;
  justify-content: space-between;
}
.hot .hd span {
  font-size: 0.53333333rem;
  font-weight: 600;
  color: #333;
}
.hot .content {
  width: 100%;
  overflow: auto;
  position: relative;
}
.hot .content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.hot .scroll {
  display: flex;
  flex-wrap: wrap;
  padding: 0 .28rem .8rem;
}
.hot .item {
  padding: 0 .12rem .12rem;
  width: 3.4rem;
  box-sizing: border-box;
  box-shadow: .2rem .1rem .4rem #f1f1f1;
}
.hot a {
  display: flex;
  flex-direction: column;
}
.hot a .pic {
  position: relative;
  overflow: hidden;
  border-radius: .14rem .14rem 0 0;
  z-index: 10;
}
.hot a .pic:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.hot a .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.hot a .info {
  padding: .22rem .1rem .1rem;
}
.hot a .info .title {
  font-size: 0.4rem;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hot a .info .hits {
  font-size: 0.34666667rem;
  color: #a0a4a3;
}
.loop {
  margin-top: .4rem;
  background: #FFF;
}
.loop .hd {
  display: flex;
  align-items: center;
  padding: .7rem .4rem 0;
  width: 100%;
  justify-content: space-between;
}
.loop .hd span {
  font-size: 0.53333333rem;
  font-weight: 600;
  color: #333;
}
.loop-item {
  padding: .34rem;
  display: flex;
  position: relative;
}
.loop-item .pic {
  position: relative;
  width: 2.346667rem;
  height: 2.346667rem;
  overflow: hidden;
  border-radius: .1356rem;
  z-index: 10;
}
.loop-item .pic:before {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.loop-item .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.loop-item .info {
  flex: 1;
  overflow: hidden;
  margin: 0 0 0 .34rem;
  position: relative;
}
.loop-item .info .title {
  font-size: 0.4rem;
  font-weight: bold;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: .6rem;
}
.loop-item .info .desc {
  font-size: 0.34666667rem;
  color: #999;
  margin: .1rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1.6rem;
  flex: 1;
}
.loop-item .info p {
  font-size: 0.32rem;
  position: absolute;
  bottom: 0;
  left: 0;
}
.loop-item .price {
  position: absolute;
  right: .4rem;
  bottom: .34rem;
  text-align: center;
}
.loop-item .price .yj {
  color: #bdbdbd;
  font-size: 0.32rem;
  text-decoration: line-through;
  margin-bottom: .06rem;
}
.loop-item .price .money {
  color: #fea130;
  font-size: 0.42666667rem;
  border: .02rem solid #fea130;
  border-radius: .4rem;
  padding: 0 .4rem;
}
.loop-item .price .money span {
  font-size: 0.42666667rem;
}
.loop-item .free {
  position: absolute;
  right: .4rem;
  bottom: .34rem;
  text-align: center;
  color: #5ec2b5;
}
